import React, { useEffect } from 'react'
import Seo from 'src/components/Seo'
import PageLayout from 'src/components/PageLayout'
import { Typography } from '@local/shared/components/System'
import Box from '@local/shared/components/System/Box'

const ThankYouPage = () => {
  useEffect(() => {
    // typeof window !== 'undefined' &&
    //   window.gtag &&
    //   window.gtag('event', 'conversion', {
    //     send_to: 'AW-666833645/cCnQCJfyrscBEO2l_L0C',
    //   })
  }, [])
  return (
    <PageLayout>
      <Seo title="Thank You" />
      <Box
        minHeight={'30rem'}
        bgcolor={'blue.light0'}
        display={'flex'}
        justifyContent={'center'}
        textAlight={'left'}
        py={'4rem'}
        px={'2rem'}
      >
        <Box maxWidth={'80rem'}>
          <Typography variant={'h1'}>Thank you!</Typography>
          <Typography variant={'subtitle'}>
            Thank you for subscribing to our mailing list!
          </Typography>
        </Box>
      </Box>
    </PageLayout>
  )
}

export default ThankYouPage
